import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Text from '@components/core/Text';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './job-post.module.scss';

const JobPost = ({
    jobPosition: { published, location, title, slug },
    background,
    sizeTitle,
    isLast,
}) => {
    const breakpoints = useBreakpoint();
    const classes = cx(
        breakpoints.md && styles.jobPostInline,
        styles.jobPost,
        !isLast && styles.jobPostBorder
    );

    const colorLocation = () => {
        if (background === 'dark') {
            return 'gray';
        }
        if (breakpoints.md) {
            return 'black';
        }
        return 'gray';
    };

    return (
        <Link to={slug}>
            <div className={classes} key={published}>
                <Text
                    as="p"
                    size={sizeTitle}
                    color={background === 'dark' ? 'white' : 'black'}
                    className={styles.jobTitle}
                >
                    {title}
                </Text>
                <Text
                    as="p"
                    size="paragraphSm"
                    color={colorLocation()}
                    className={styles.jobLocation}
                >
                    {location}
                </Text>
            </div>
        </Link>
    );
};

JobPost.propTypes = {
    jobPosition: PropTypes.shape({
        published: PropTypes.instanceOf(Date).isRequired,
        title: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    background: PropTypes.string,
    sizeTitle: PropTypes.string,
    isLast: PropTypes.bool,
};

JobPost.defaultProps = {
    background: 'dark',
    sizeTitle: 'paragraphSm',
    isLast: false,
};

export default JobPost;
