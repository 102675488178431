import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Text from '@components/core/Text';
import Testimony from '@components/Testimony';
import styles from './testimony-carousel.module.scss';

const TestimonyCarousel = ({ slides }) => {
    const settings = {
        arrows: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 10000,
        dots: true,
        customPaging: i => {
            return (
                <div className={styles.bullet}>
                    <Text
                        as="span"
                        size="paragraphSm"
                        className={styles.bulletTitle}
                    >
                        {`${i + 1}`.padStart(2, 0)}
                    </Text>
                </div>
            );
        },
    };
    return (
        <Slider className={styles.carousel} {...settings}>
            {slides.map(({ name, role, testimony, image }) => (
                <Testimony
                    key={name}
                    name={name}
                    role={role}
                    quote={testimony}
                    image={image}
                />
            ))}
        </Slider>
    );
};

TestimonyCarousel.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.any),
};

TestimonyCarousel.defaultProps = {
    slides: [],
};

export default TestimonyCarousel;
