import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid from '@components/core/Grid';
import styles from './horizontal-scroll-box.module.scss';

const handleDynamicHeight = (ref, setDynamicHeight) => {
    const width = ref.current ? ref.current.scrollWidth : 0;
    const dynamicHeight =
        width - (window.innerWidth - window.innerHeight) + width * 0.6;
    setDynamicHeight(dynamicHeight);
};

const HorizontalScrollBox = ({
    children,
    className,
    topContent,
    bottomContent,
    onHorizontalScroll,
}) => {
    const breakpoint = useBreakpoint();

    const [dynamicHeight, setDynamicHeight] = useState(null);
    const [translateX, setTranslateX] = useState(0);

    const containerRef = useRef(null);
    const objectRef = useRef(null);

    const resizeHandler = () => {
        handleDynamicHeight(objectRef, setDynamicHeight);
    };

    const scrollContainerStyle = breakpoint.md
        ? { height: `${dynamicHeight}px` }
        : null;

    const scrollTranslateStyle = breakpoint.md
        ? { transform: `translateX(${translateX}px)` }
        : null;

    useEffect(() => {
        handleDynamicHeight(objectRef, setDynamicHeight);

        const onScroll = () => {
            const offsetTop =
                containerRef.current && -containerRef.current.offsetTop;
            const offsetHeight =
                containerRef.current && containerRef.current.offsetHeight;
            const scrollPosition = dynamicHeight - offsetHeight;
            const scrollProgress = (Math.abs(offsetTop) / scrollPosition) * 100;

            onHorizontalScroll(Math.floor(scrollProgress));
            setTranslateX(offsetTop);
        };

        window.addEventListener('scroll', onScroll);
        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
            window.removeEventListener('scroll', onScroll);
        };
    }, [containerRef.current]);

    return (
        <div
            className={`${styles.horizontalScrollBox} ${className}`}
            style={scrollContainerStyle}
        >
            <div className={styles.stickyInnerContainer} ref={containerRef}>
                <div className={styles.topContent}>{topContent}</div>
                <div
                    className={styles.horizontalTranslateContainer}
                    style={scrollTranslateStyle}
                    ref={objectRef}
                >
                    {children}
                </div>
                <Grid>
                    <div className={styles.bottomContent}>{bottomContent}</div>
                </Grid>
            </div>
        </div>
    );
};

HorizontalScrollBox.defaultProps = {
    className: '',
    topContent: null,
    bottomContent: null,
    onHorizontalScroll: () => {},
};

HorizontalScrollBox.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    topContent: PropTypes.node,
    bottomContent: PropTypes.node,
    onHorizontalScroll: PropTypes.func,
};

export default HorizontalScrollBox;
