import React, { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Section from '@components/core/Section';
import Text from '@components/core/Text';
import Grid, { Row, Column } from '@components/core/Grid';
import Hero from '@components/Hero';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import AboutUs from '@sections/Home/AboutUs';
import OurWork from '@sections/Home/OurWork';
import Insights from '@sections/Home/Insights';
import Careers from '@sections/Home/Careers';
import ScrollFadeIn from '@components/core/ScrollFadeIn';

import styles from './home.module.scss';

const minHeightToPassHeroSectionDesktop = 780;
const minHeightToPassHeroSectionMobile = 420;

const HomePage = ({
    data: {
        markdownRemark: {
            frontmatter: {
                seo,
                title,
                header,
                aboutUs,
                careers,
                insights,
                works,
            },
            html,
        },
    },
}) => {
    const breakpoints = useBreakpoint();
    const [navBarTheme, setNavBarTheme] = useState(() =>
        breakpoints.md ? 'light' : 'dark'
    );
    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    // Ths function will work to include there the logic to determine if a section is dark or light
    const isALightSection = scrollPos => {
        const heightToChange = breakpoints.md
            ? minHeightToPassHeroSectionDesktop
            : minHeightToPassHeroSectionMobile;
        if (scrollPos && scrollPos > heightToChange) {
            return true;
        }
        return false;
    };

    const handleScroll = throttle(() => {
        const scrollPos = window.scrollY || window.scrollTop;
        if (isALightSection(scrollPos)) {
            setNavBarTheme('light');
        } else {
            setNavBarTheme('dark');
        }
    }, 100);

    useEffect(() => {
        handleScroll();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Layout
            fixedHeader
            navBarTheme={navBarTheme}
            disableFooterParallax={false}
        >
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Hero data={header} dataTheme="dark" />
            <Section className={styles.homeOverviewSection}>
                <Grid>
                    <Row>
                        <Column>
                            <ScrollFadeIn
                                thresholdAreaRatio={breakpoints.md ? 0.4 : 0.3}
                            >
                                <div className={styles.homeOverview}>
                                    <Text
                                        as="div"
                                        size="h4"
                                        dangerouslySetInnerHTML={{
                                            __html: html,
                                        }}
                                        className={styles.overviewParagraph}
                                    />
                                </div>
                            </ScrollFadeIn>
                        </Column>
                    </Row>
                </Grid>
            </Section>
            <AboutUs className={styles.homeOverviewSection} data={aboutUs} />
            <OurWork data={works} />
            <Insights data={insights} />
            <Careers data={careers} />
        </Layout>
    );
};

HomePage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query HomePage {
        markdownRemark(fields: { slug: { eq: "/pages/home/" } }) {
            frontmatter {
                title
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                header {
                    title
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    videoUrl
                    previewUrl {
                        src: publicURL
                    }
                    video {
                        src: publicURL
                    }
                }
                aboutUs {
                    sectionHeader {
                        description
                        eyebrow
                        title
                    }
                }
                careers {
                    sectionHeader {
                        description
                        eyebrow
                        title
                    }
                }
                insights {
                    sectionHeader {
                        eyebrow
                        title
                    }
                }
                works {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
            }
            html
        }
    }
`;

export default HomePage;
