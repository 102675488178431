import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import cx from 'classnames';
import ZoomInImage from '@components/core/ZoomInEffect';
import Text from '@components/core/Text';
import { Parallax } from 'react-scroll-parallax';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import styles from './case-study.module.scss';

const CaseStudy = ({ caseStudy, position }) => {
    const breakpoints = useBreakpoint();
    const positionStyle = styles[position];
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className={`${styles.caseStudy} ${positionStyle}`}
            data-testid="case-study"
        >
            <div
                className={`${styles.coverImageSection} ${positionStyle}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <ZoomInImage isHovered={isHovered}>
                    <Link to={caseStudy.slug}>
                        <div className={styles.imageWrapper}>
                            <Img
                                className={styles.coverImage}
                                fluid={
                                    caseStudy.coverImage.childImageSharp.fluid
                                }
                                alt={`Case Study - ${caseStudy.heading}`}
                            />
                            {caseStudy.clientLogo &&
                                caseStudy.clientLogo.childImageSharp && (
                                    <div className={styles.clientLogo}>
                                        <Img
                                            fluid={
                                                caseStudy.clientLogo
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                )}
                        </div>
                    </Link>
                </ZoomInImage>
            </div>
            <div className={cx(styles.descriptionSection, positionStyle)}>
                <Parallax y={[50, -50]} disabled={!breakpoints.md}>
                    <Text
                        size="paragraphSm"
                        as="p"
                        className={cx(styles.heading, positionStyle, {
                            [styles.hover]: isHovered,
                        })}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {caseStudy.heading}
                    </Text>
                    <h5 className={styles.subHeading}>
                        <Link to={caseStudy.slug}>
                            <Text
                                as="h5"
                                size="h5"
                                className={cx(
                                    styles.subHeadingText,
                                    positionStyle,
                                    {
                                        [styles.hover]: isHovered,
                                    }
                                )}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                {caseStudy.subHeading}
                            </Text>
                        </Link>
                    </h5>
                    <div
                        className={cx(styles.caseStudyLink, {
                            [styles.hover]: isHovered,
                        })}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <Link to={caseStudy.slug}>
                            <Text as="span" size="paragraphSm">
                                Learn more
                            </Text>
                        </Link>
                    </div>
                </Parallax>
            </div>
        </div>
    );
};

CaseStudy.propTypes = {
    position: PropTypes.oneOf(['even', 'odd']).isRequired,
    caseStudy: PropTypes.shape({
        draft: PropTypes.bool,
        archived: PropTypes.bool,
        slug: PropTypes.string,
        heading: PropTypes.string,
        subHeading: PropTypes.string,
        published: PropTypes.string,
        coverImage: PropTypes.shape({
            publicURL: PropTypes.string,
            childImageSharp: PropTypes.shape({
                fluid: PropTypes.objectOf(PropTypes.any),
            }),
        }),
        clientLogo: PropTypes.shape({
            publicURL: PropTypes.string,
            childImageSharp: PropTypes.shape({
                fluid: PropTypes.objectOf(PropTypes.any),
            }),
        }),
    }).isRequired,
};

export default CaseStudy;
