import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid, { Row, Column } from '@components/core/Grid';
import ArticleCard from '@components/Cards/Article';
import CarouselWithPadding from '@components/Carousels/CarouselWithPadding';
import AnchorToSection from '@components/core/AnchorToSection/AnchorToSection';
import Text from '@components/core/Text';
import SectionHeader from '@components/SectionHeader';
import ParallaxSection from '@components/core/ParallaxSection';
import { formatData, makeCustomSlugArticle } from '../../../utils/utils';
import styles from './insights.module.scss';

const MultipleArticles = ({ articles }) =>
    articles.map(
        ({
            published,
            slug,
            image,
            imageVertical,
            category,
            title,
            featuresText: text,
            customSlug,
        }) => {
            const article = {
                slug: makeCustomSlugArticle(slug, customSlug),
                image: imageVertical || image,
                topic: category,
                title,
                published,
                text,
            };
            return (
                <ArticleCard
                    key={article.published}
                    article={article}
                    sizeTitle="h5"
                    colorTitle="white"
                />
            );
        }
    );

const GetInsightsHome = graphql`
    query GetInsightsHome {
        insightsHome: allMarkdownRemark(
            limit: 4
            sort: { fields: [frontmatter___homePage], order: ASC }
            filter: {
                fields: { slug: { glob: "/insights/blog/*" } }
                frontmatter: { archive: { eq: false }, homePage: { ne: null } }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        customSlug: slug
                        published
                        featuredText
                        category
                        featured
                        homePage
                        image {
                            childImageSharp {
                                fluid(maxWidth: 608, maxHeight: 800) {
                                    ...GatsbyImageSharpFluid
                                    ...GatsbyImageSharpFluidLimitPresentationSize
                                }
                            }
                        }
                        imageVertical {
                            childImageSharp {
                                fluid(maxWidth: 608, maxHeight: 800) {
                                    ...GatsbyImageSharpFluid
                                    ...GatsbyImageSharpFluidLimitPresentationSize
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        reportsHome: allMarkdownRemark(
            limit: 4
            sort: { fields: [frontmatter___homePage], order: ASC }
            filter: {
                fields: { slug: { regex: "/insights-reports/" } }
                frontmatter: {
                    draft: { eq: false }
                    archived: { eq: false }
                    homePage: { ne: null }
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        eyebrow
                        title
                        reportUrl
                        homePage
                        coverImage {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                    ...GatsbyImageSharpFluidLimitPresentationSize
                                }
                            }
                        }
                        imageVertical {
                            childImageSharp {
                                fluid(maxWidth: 608, maxHeight: 800) {
                                    ...GatsbyImageSharpFluid
                                    ...GatsbyImageSharpFluidLimitPresentationSize
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Insights = ({ data }) => {
    const breakpoints = useBreakpoint();
    const { insightsHome = {}, reportsHome = {} } = useStaticQuery(
        GetInsightsHome
    );
    const reports = formatData(reportsHome.edges).map(
        ({ eyebrow, coverImage, reportUrl, ...rest }) => ({
            category: eyebrow,
            image: coverImage,
            slug: reportUrl,
            ...rest,
        })
    );
    const articles = formatData(insightsHome.edges);

    // Order reports and articles to show only 4 and in the order needed.
    const shownInsights = sortBy([...reports, ...articles], ['homePage']).slice(
        0,
        4
    );

    return (
        <ParallaxSection
            variant="dark"
            dataTheme="dark"
            className={styles.container}
            height="350px"
            disabled={!breakpoints.md}
            bottomDisabled
        >
            <Grid>
                <SectionHeader
                    className={styles.header}
                    eyebrow={data.sectionHeader.eyebrow}
                    title={data.sectionHeader.title}
                    summary={data.sectionHeader.description}
                    variant="dark"
                />
            </Grid>
            <Grid>
                <Row>
                    <Column md="10">
                        {breakpoints.md ? (
                            <div className={styles.section}>
                                <MultipleArticles articles={shownInsights} />
                            </div>
                        ) : (
                            <CarouselWithPadding infinite>
                                {shownInsights.map(
                                    ({
                                        published,
                                        slug,
                                        customSlug,
                                        image,
                                        category,
                                        title: articleTitle,
                                        featuredText: text,
                                    }) => {
                                        const article = {
                                            slug: makeCustomSlugArticle(
                                                slug,
                                                customSlug
                                            ),
                                            image,
                                            topic: category,
                                            title: articleTitle,
                                            published,
                                            text,
                                        };
                                        return (
                                            <ArticleCard
                                                key={published}
                                                article={article}
                                                sizeTitle="h5"
                                                colorTitle="white"
                                                classCardWrapper={
                                                    styles.cardWrapper
                                                }
                                                classImageWrapper={
                                                    styles.imageWrapper
                                                }
                                            />
                                        );
                                    }
                                )}
                            </CarouselWithPadding>
                        )}
                    </Column>
                    <Column md="2">
                        <AnchorToSection
                            toPath="/insights/"
                            sizeIcon="4"
                            className={styles.viewMore}
                            animatedInHover
                        >
                            <Text as="span" size="paragraphSm">
                                View more posts
                            </Text>
                        </AnchorToSection>
                    </Column>
                </Row>
            </Grid>
        </ParallaxSection>
    );
};

Insights.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
    }).isRequired,
};

export default Insights;
