import React from 'react';
import PropTypes from 'prop-types';
import Grid, { Column, Row } from '@components/core/Grid';
import TestimonyCarousel from '@components/Carousels/TestimonyCarousel';
import { useStaticQuery, graphql } from 'gatsby';
import JobsList from '@components/JobsList';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import styles from './careers.module.scss';

const sectionQuery = graphql`
    query CareersSectionQuery {
        testimonials: allMarkdownRemark(
            filter: { fields: { slug: { regex: "/testimony/" } } }
            limit: 3
            sort: { order: DESC, fields: frontmatter___published }
        ) {
            edges {
                node {
                    frontmatter {
                        name
                        testimony
                        role
                        image {
                            childImageSharp {
                                fluid(maxWidth: 600) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        jobs: allMarkdownRemark(
            limit: 5
            filter: {
                fields: { slug: { regex: "/jobs/" } }
                frontmatter: { status: { eq: "open" } }
            }
            sort: { order: DESC, fields: frontmatter___published }
        ) {
            nodes {
                frontmatter {
                    title
                    location
                    published
                }
                fields {
                    slug
                }
            }
        }
    }
`;

const Careers = ({ data }) => {
    const { testimonials, jobs } = useStaticQuery(sectionQuery);
    const testimonialSlides = testimonials.edges.reduce((acc, el) => {
        const image = el.node.frontmatter.image
            ? el.node.frontmatter.image.childImageSharp.fluid
            : null;
        const slide = {
            ...el.node.frontmatter,
            image: {
                fluid: image,
            },
        };
        acc.push(slide);
        return acc;
    }, []);

    const jobsList = jobs.nodes.reduce((acc, el) => {
        const jobPosition = {
            ...el.frontmatter,
            ...el.fields,
            published: new Date(el.frontmatter.published),
        };
        acc.push(jobPosition);
        return acc;
    }, []);

    return (
        <Section
            variant="dark"
            dataTheme="dark"
            className={styles.careersSection}
        >
            <Grid>
                <Row>
                    <Column md="5">
                        <SectionHeader
                            className={styles.sectionHeader}
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                            variant="dark"
                        />
                        <JobsList jobsList={jobsList} />
                    </Column>
                    <Column md="6" offsetMd="1">
                        <TestimonyCarousel slides={testimonialSlides} />
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

Careers.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
        link: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
};

export default Careers;
