import React from 'react';
import PropTypes from 'prop-types';
import CaseStudy from '@components/CaseStudy';
import useCaseStudiesOverview from '@hooks/useCaseStudies';
import AnchorToSection from '@components/core/AnchorToSection';
import Section from '@components/core/Section';
import Text from '@components/core/Text';
import Grid, { Row, Column } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import ScrollFadeIn from '@components/core/ScrollFadeIn';
import styles from './our-work.module.scss';

const OurWork = ({ data }) => {
    const caseStudies = useCaseStudiesOverview();

    return (
        <Section
            variant={data.variantStyle || 'light'}
            className={styles.ourWorkSection}
        >
            <Grid>
                <Row>
                    <Column md="8">
                        <ScrollFadeIn>
                            <SectionHeader
                                className={styles.headerSection}
                                eyebrow={data.sectionHeader.eyebrow}
                                title={data.sectionHeader.title}
                                summary={data.sectionHeader.description}
                            />
                        </ScrollFadeIn>
                    </Column>
                </Row>
            </Grid>

            <Grid>
                <div
                    className={styles.caseStudiesContainer}
                    data-testid="our-work-home-section"
                >
                    <div
                        className={styles.caseStudiesList}
                        data-testid="our-work-case-studies"
                    >
                        {caseStudies &&
                            caseStudies
                                .slice(0, 3)
                                .map((caseStudy, index) => (
                                    <CaseStudy
                                        key={`case-study-${caseStudy.heading}`}
                                        caseStudy={caseStudy}
                                        position={index % 2 ? 'odd' : 'even'}
                                    />
                                ))}
                    </div>
                </div>
            </Grid>
            <Grid>
                <div
                    className={styles.linkSection}
                    data-testid="our-work-anchor"
                >
                    <AnchorToSection
                        toPath="/work/"
                        sizeIcon="4"
                        className={styles.viewMore}
                        animatedInHover
                    >
                        <Text as="span" size="paragraphMd">
                            View more of our work
                        </Text>
                    </AnchorToSection>
                </div>
            </Grid>
        </Section>
    );
};

OurWork.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
        link: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
};

export default OurWork;
