import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Eyebrow from '@components/core/Eyebrow';
import Quote from '@components/core/Quote';
import Img from 'gatsby-image';
import styles from './testimony.module.scss';

const Testimony = ({ name, role, quote, image: { title, alt, fluid } }) => {
    return (
        <div data-testid="testimony">
            <Img
                className={styles.thumbnail}
                fluid={fluid}
                title={title}
                alt={alt}
            />
            <Text className={styles.title} as="cite" color="white" size="h5">
                {name}
            </Text>
            <Eyebrow
                className={styles.eyebrow}
                casing="uppercase"
                size="paragraphXs"
            >
                {role}
            </Eyebrow>
            <Quote size="medium">{quote}</Quote>
        </div>
    );
};

Testimony.propTypes = {
    name: PropTypes.string,
    role: PropTypes.string,
    quote: PropTypes.string,
    image: PropTypes.shape({
        title: PropTypes.string,
        alt: PropTypes.string,
        fluid: PropTypes.shape({
            aspectRatio: PropTypes.number.isRequired,
            src: PropTypes.string.isRequired,
            srcSet: PropTypes.string.isRequired,
            sizes: PropTypes.string.isRequired,
            base64: PropTypes.string,
            tracedSVG: PropTypes.string,
            srcWebp: PropTypes.string,
            srcSetWebp: PropTypes.string,
            media: PropTypes.string,
            maxWidth: PropTypes.number,
            maxHeight: PropTypes.number,
        }),
    }),
};

Testimony.defaultProps = {
    name: '',
    role: '',
    quote: '',
    image: {},
};

export default Testimony;
