import { useStaticQuery, graphql } from 'gatsby';

export const formatData = nodesData => {
    if (!nodesData || !Array.isArray(nodesData)) {
        return nodesData;
    }

    return nodesData.map(caseStudy => {
        return {
            slug: caseStudy.data.fieldSlug, // TODO: Add the proper slug configuration once the pages are created
            body: caseStudy.html,
            ...caseStudy.data,
        };
    });
};

export default () => {
    const data = useStaticQuery(graphql`
        query GetAllActiveAboutUsDescription {
            result: allMarkdownRemark(
                limit: 3
                filter: {
                    fields: { slug: { regex: "/about-us/" } }
                    frontmatter: {
                        draft: { eq: false }
                        archived: { eq: false }
                    }
                }
                sort: { order: DESC, fields: [frontmatter___published] }
            ) {
                nodes {
                    data: frontmatter {
                        draft
                        archived
                        title
                        published(formatString: "YYYY-MM-DD HH:mm:ss")
                        overview
                        coverImage {
                            publicURL
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                    presentationWidth
                                }
                            }
                        }
                        fieldSlug: slug
                        outsideUrl
                    }
                    fields {
                        slug
                    }
                    html
                }
                totalCount
            }
        }
    `);

    const { nodes } = data.result;

    return nodes && formatData(nodes);
};
