import React from 'react';
import PropTypes from 'prop-types';
import JobPost from '@components/JobPost';
import AnchorToSection from '@components/core/AnchorToSection/AnchorToSection';
import styles from './jobs-list.module.scss';

const JobsList = ({ jobsList, background, sizeJobTitle, viewAll }) => {
    const lastIndex = jobsList.length - 1;
    return (
        <div className={styles.jobsListContainer}>
            <div className={styles.jobsList}>
                {jobsList.map((jobPosition, index) => (
                    <JobPost
                        key={jobPosition.published}
                        jobPosition={jobPosition}
                        background={background}
                        sizeTitle={sizeJobTitle}
                        isLast={lastIndex === index}
                    />
                ))}
            </div>
            {viewAll && (
                <div className={styles.anchorSection}>
                    <AnchorToSection
                        sizeIcon="4"
                        toPath="/careers/"
                        animatedInHover
                    >
                        <div className={styles.allOpenings}>
                            View all openings
                        </div>
                    </AnchorToSection>
                </div>
            )}
        </div>
    );
};

JobsList.propTypes = {
    jobsList: PropTypes.arrayOf(PropTypes.any),
    background: PropTypes.string,
    sizeJobTitle: PropTypes.string,
    viewAll: PropTypes.bool,
};

JobsList.defaultProps = {
    jobsList: [],
    background: 'dark',
    sizeJobTitle: 'paragraphSm',
    viewAll: true,
};

export default JobsList;
