import React, { useState, useEffect, useContext, useRef } from 'react';
import { Fade, Slide } from '@transitions';
import Text from '@components/core/Text';
import Video from '@components/Video';
import Img from 'gatsby-image';
import Modal from '@components/core/Modal/Modal';
import useModal from '@hooks/useModal';
import ScrollDown from '@components/Icons/down-arrow.svg';
import ScrollDownText from '@components/Icons/scroll-down.svg';
import PropTypes from 'prop-types';
import Markdown from 'react-remarkable';
import { Parallax } from 'react-scroll-parallax';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Section from '@components/core/Section';
import Grid from '@components/core/Grid';
import BgCircleAndLines from '@assets/images/bg-circle-and-lines.svg';
import ApplicationContext from '@contexts/ApplicationContext';
import useRect from '@hooks/useRect';
import styles from './hero.module.scss';
import ReelVideo from './ReelVideo';

const Hero = ({
    data: { title, video, previewUrl, image, videoUrl },
    dataTheme,
}) => {
    const breakpoints = useBreakpoint();
    const { appState } = useContext(ApplicationContext);
    const [itemModalOpen, setItemModalOpen, toggleModal] = useModal(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isHover, setIsHover] = useState(false);
    const reelVideoRef = useRef(null);
    const {
        height: reelVideoHeight,
        width: reelVideoWidth,
        top: reelVideoTop,
        left: reelVideoLeft,
    } = useRect(reelVideoRef);

    useEffect(() => {
        if (itemModalOpen) {
            document.querySelector('body').classList.add('modal-video-open');
        } else {
            document.querySelector('body').classList.remove('modal-video-open');
        }
    }, [itemModalOpen]);

    const actionHandleVideo = e => {
        e.stopPropagation();
        return setIsPlaying(!isPlaying);
    };

    const renderTitle = () => {
        let yOffsetConfig = [30, -20];

        if (breakpoints.sm) yOffsetConfig = [40, -10];
        if (breakpoints.md) yOffsetConfig = [70, -40];

        return (
            <Parallax y={yOffsetConfig} disabled={!breakpoints.md}>
                <Slide
                    in
                    delay="300ms"
                    transformDuration={250}
                    opacityDuration={600}
                    withOpacity
                >
                    <Text
                        className={styles.title}
                        as="div"
                        size="h1"
                        color="white"
                    >
                        <Markdown>{title}</Markdown>
                    </Text>
                </Slide>
            </Parallax>
        );
    };

    return (
        <Section
            as="div"
            variant="light"
            className={styles.hero}
            dataTheme={dataTheme}
            style={{
                height: breakpoints.md
                    ? `${window.innerHeight - appState.headerHeight}px`
                    : undefined,
            }}
        >
            <Grid maxWidthFull={!breakpoints.sm} style={{ height: '100%' }}>
                <div className={styles.heroBody}>
                    <div className={styles.scrollDownIcon}>
                        <ScrollDownText className={styles.scrollDownIconText} />
                        <ScrollDown />
                    </div>
                    {previewUrl ? (
                        <ReelVideo
                            isHover={isHover}
                            ref={reelVideoRef}
                            url={previewUrl.src}
                            className={styles.reelVideo}
                            showButton={!!(videoUrl || video)}
                        />
                    ) : (
                        <div className={styles.reelImage}>
                            <Img fluid={image.childImageSharp.fluid} />
                        </div>
                    )}
                    <div className={styles.bgCircleAndLinesWrapper}>
                        <Parallax y={[20, -30]} disabled={!breakpoints.md}>
                            <Fade in appear opacityDuration={200} delay="1s">
                                <img
                                    src={BgCircleAndLines}
                                    alt="Zemoga BG"
                                    className={styles.bgCircleAndLines}
                                />
                            </Fade>
                        </Parallax>
                    </div>
                    {renderTitle()}
                    {(videoUrl || (video && video.src)) && (
                        <div
                            className={styles.reelVideoClickableArea}
                            onClick={toggleModal}
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                            onKeyDown={toggleModal}
                            aria-label="Open Reel"
                            tabIndex={0}
                            role="button"
                            style={{
                                height: reelVideoHeight,
                                left: reelVideoLeft,
                                top: reelVideoTop,
                                width: reelVideoWidth,
                            }}
                        />
                    )}
                </div>
            </Grid>
            {(videoUrl || (video && video.src)) && (
                <Modal
                    isTransparent
                    isActive={itemModalOpen}
                    handleClose={() => setItemModalOpen(false)}
                    handleBodyModal={e => actionHandleVideo(e)}
                >
                    <Video
                        onModal
                        playing={isPlaying}
                        src={videoUrl || (video && video.src)}
                    />
                </Modal>
            )}
        </Section>
    );
};

Hero.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        video: PropTypes.shape({
            src: PropTypes.string,
        }),
        videoUrl: PropTypes.string,
        previewUrl: PropTypes.shape({
            src: PropTypes.string,
        }),
        image: PropTypes.shape({
            childImageSharp: PropTypes.shape({
                title: PropTypes.string,
                alt: PropTypes.string,
                fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number.isRequired,
                    src: PropTypes.string.isRequired,
                    srcSet: PropTypes.string.isRequired,
                    sizes: PropTypes.string.isRequired,
                    base64: PropTypes.string,
                    tracedSVG: PropTypes.string,
                    srcWebp: PropTypes.string,
                    srcSetWebp: PropTypes.string,
                    media: PropTypes.string,
                    maxWidth: PropTypes.number,
                    maxHeight: PropTypes.number,
                }),
            }),
        }).isRequired,
    }).isRequired,
    dataTheme: PropTypes.oneOf(['light', 'dark']),
};

Hero.defaultProps = {
    dataTheme: 'light',
};

export default Hero;
