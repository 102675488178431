import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnchorToSection from '@components/core/AnchorToSection';
import useAboutUs from '@hooks/useAboutUs';
import HorizontalScrollBox from '@components/HorizontalScrollBox';
import ScrollCard from '@components/Cards/Scroll';
import Section from '@components/core/Section';
import Text from '@components/core/Text';
import Grid, { Column, Row } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import ScrollFadeIn from '@components/core/ScrollFadeIn';
import styles from './about-us.module.scss';
import { BREAKPOINTS } from '../../../utils/const';

const AboutUs = ({ data }) => {
    const aboutUsDesc = useAboutUs();
    const [viewMoreTransitionStyle, setViewMoreTransitionStyle] = useState({
        opacity: 1,
        transform: 'translateX(0px)',
    });

    const onHorizontalScroll = scrollProgress => {
        let startOnScrollProgress = 66;
        if (BREAKPOINTS.md === window.innerWidth) {
            startOnScrollProgress = 68;
        } else if (BREAKPOINTS.xl === window.innerWidth) {
            startOnScrollProgress = 70;
        }
        const transitionProgress =
            scrollProgress > startOnScrollProgress
                ? (scrollProgress - startOnScrollProgress) / 20
                : 0;
        const viewMoreOpacity =
            scrollProgress > startOnScrollProgress
                ? Math.abs(transitionProgress - 1)
                : 1;
        const viewMoreTranslateX = transitionProgress * 240;

        setViewMoreTransitionStyle({
            opacity: viewMoreOpacity,
            transform: `translateX(-${viewMoreTranslateX}px)`,
        });
    };

    const descriptionSection = (
        <ScrollFadeIn>
            <SectionHeader
                data-testid="about-us-header"
                eyebrow={data.sectionHeader.eyebrow}
                title={data.sectionHeader.title}
                summary={data.sectionHeader.description}
            />
        </ScrollFadeIn>
    );

    const linkToSection = (
        <div className={styles.linkSection} data-testid="about-us-anchor">
            <AnchorToSection
                toPath="/about-us/"
                sizeIcon="4"
                className={styles.viewMore}
                style={viewMoreTransitionStyle}
                animatedInHover
            >
                <Text as="span" size="paragraphMd">
                    Learn more about us
                </Text>
            </AnchorToSection>
        </div>
    );

    return (
        <Section
            variant={data.variantStyle || 'light'}
            className={styles.aboutUsSection}
        >
            <Grid>
                <div data-testid="about-us-home-section">
                    <Row>
                        <Column md="8">{descriptionSection}</Column>
                    </Row>
                </div>
            </Grid>
            <Grid maxWidthFull>
                <div className={styles.horizontalSection}>
                    <HorizontalScrollBox
                        bottomContent={linkToSection}
                        onHorizontalScroll={onHorizontalScroll}
                    >
                        <div
                            className={styles.cardsContainer}
                            data-testid="about-us-overviews"
                        >
                            {aboutUsDesc.map(aboutUs => (
                                <ScrollCard
                                    data={aboutUs}
                                    key={aboutUs.title}
                                />
                            ))}
                        </div>
                    </HorizontalScrollBox>
                </div>
            </Grid>
        </Section>
    );
};

AboutUs.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        variantStyle: PropTypes.string,
        link: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
};

export default AboutUs;
