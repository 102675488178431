import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ZoomInImage from '@components/core/ZoomInEffect';
import Text from '@components/core/Text';
import cx from 'classnames';
import styles from './scroll-card.module.scss';

const ScrollCard = ({
    className,
    data: { slug, title, overview, coverImage, outsideUrl },
}) => {
    const isLinkCard = Boolean(slug) || Boolean(outsideUrl);
    const [isHovered, setIsHovered] = useState(false);
    const classes = cx(className, styles.scrollCard, {
        [styles.scrollCardLink]: isLinkCard,
    });
    return (
        <a
            className={classes}
            data-testid="scroll-card"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            href={slug || outsideUrl}
            target={outsideUrl ? '_blank' : '_self'}
            rel="noreferrer"
        >
            <div className={styles.imageContainer}>
                <ZoomInImage isHovered={isLinkCard && isHovered}>
                    <Img
                        className={styles.coverImage}
                        fluid={coverImage.childImageSharp.fluid}
                        alt={`${title}`}
                    />
                </ZoomInImage>
            </div>
            <div className={styles.cardInformation}>
                <Text as="h3" size="h3" className={styles.title}>
                    {title}
                </Text>
                {overview && (
                    <p className={styles.overview}>
                        <Text as="span" size="paragraphSm">
                            {overview}
                        </Text>
                    </p>
                )}
                {(slug || outsideUrl) && (
                    <div className={styles.link}>
                        <Text as="span" size="paragraphSm">
                            Learn more
                        </Text>
                    </div>
                )}
            </div>
        </a>
    );
};

ScrollCard.defaultProps = {
    className: '',
};

ScrollCard.propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        draft: PropTypes.bool,
        archived: PropTypes.bool,
        slug: PropTypes.string,
        outsideUrl: PropTypes.string,
        title: PropTypes.string,
        published: PropTypes.string,
        overview: PropTypes.string,
        coverImage: PropTypes.shape({
            publicURL: PropTypes.string,
            childImageSharp: PropTypes.shape({
                fluid: PropTypes.objectOf(PropTypes.any),
            }),
        }),
    }).isRequired,
};

export default ScrollCard;
