import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import useRect from '@hooks/useRect';
import easing from '@utils/easing';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const ScrollFadeIn = ({ children, thresholdAreaRatio }) => {
    const wrapperRef = useRef(null);
    const [wrapperOpacityPercent, setWrapperOpacityPercent] = useState(0);
    const wrapperRect = useRect(wrapperRef);
    const breakpoints = useBreakpoint();

    useLayoutEffect(() => {
        const wrapperTop = wrapperRect.top + window.scrollY;
        const wrapperHeight = wrapperRect.bottom - wrapperRect.top;

        const onScroll = throttle(() => {
            const scrollPos = window.scrollY || window.scrollTop;
            const bottomScrollPos = scrollPos + window.innerHeight;
            const visibilityThreshold =
                bottomScrollPos - wrapperHeight * thresholdAreaRatio;

            if (!breakpoints.sm) {
                setWrapperOpacityPercent(100);
            } else if (wrapperTop < visibilityThreshold) {
                let overviewSecPercent =
                    ((visibilityThreshold - wrapperTop) * 100) / wrapperHeight;

                if (overviewSecPercent > 100) overviewSecPercent = 100;
                if (overviewSecPercent < 0) overviewSecPercent = 0;

                setWrapperOpacityPercent(overviewSecPercent);
            } else if (wrapperTop > visibilityThreshold) {
                setWrapperOpacityPercent(0);
            }
        }, 100);

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [wrapperRect]);

    return (
        <div
            ref={wrapperRef}
            style={{
                opacity: easing.easeOutQuad(wrapperOpacityPercent / 100),
            }}
        >
            {children}
        </div>
    );
};

ScrollFadeIn.propTypes = {
    children: PropTypes.node.isRequired,
    thresholdAreaRatio: PropTypes.number,
};

ScrollFadeIn.defaultProps = {
    thresholdAreaRatio: 1,
};

export default ScrollFadeIn;
