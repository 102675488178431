import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PlaybackButton from '@components/core/PlaybackButton';
import { Slide } from '@transitions';
import { Parallax } from 'react-scroll-parallax';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './reel-video.module.scss';

const ReelVideo = React.forwardRef(
    ({ url, style, className, isHover, showButton }, ref) => {
        const [isLoadedMedia, setIsLoadedMedia] = useState(false);
        const breakpoints = useBreakpoint();
        const reelVideojoinedClassName = cx(styles.reelVideo, {
            [styles.hover]: isHover,
            [styles.reelVideoGradient]: isLoadedMedia,
        });

        return (
            <div ref={ref} className={className} style={style}>
                <Parallax y={[-10, 10]} disabled={!breakpoints.md}>
                    <Slide in delay="100ms">
                        <div className={reelVideojoinedClassName}>
                            <ReactPlayer
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate3d(-50%, -50%, 0)',
                                }}
                                url={url}
                                width="200%"
                                height="200%"
                                controls={false}
                                playing
                                muted
                                loop
                                playsinline
                                onReady={() => setIsLoadedMedia(true)}
                            />
                            {showButton && (
                                <PlaybackButton
                                    tabIndex={0}
                                    variant="reel"
                                    className={styles.playbackButton}
                                />
                            )}
                        </div>
                    </Slide>
                </Parallax>
            </div>
        );
    }
);

ReelVideo.propTypes = {
    url: PropTypes.string.isRequired,
    style: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    isHover: PropTypes.bool,
    showButton: PropTypes.bool,
};

ReelVideo.defaultProps = {
    style: {},
    className: '',
    isHover: false,
    showButton: false,
};

export default ReelVideo;
